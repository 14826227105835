/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import HeadingBlock from "../../components/HeadingBlock";
import PageHead from "../../components/PageHead";
import teamspirit from "../../images/skills/undraw_team_spirit_re_yl1v.svg";
import creativeexpert from "../../images/skills/undraw_creative_experiment_-8-dk3.svg";
import softwreengineer from "../../images/skills/undraw_software_engineer_re_fyew.svg";
import appreciation from "../../images/skills/undraw_appreciation_re_p6rl.svg";
import experiencedesign from "../../images/skills/undraw_experience_design_eq-3-j.svg";
import ideas from "../../images/skills/undraw_new_ideas_jdea.svg";
import process from "../../images/skills/undraw_thought_process_re_om58.svg";
import typing from "../../images/skills/undraw_typing_re_d4sq.svg";
import { team } from "../../data/team";
import CallToAction from "../../components/CallToAction";
import { useTranslation } from "react-i18next";
import "../Home/i18n";

export default function About() {

  const { t } = useTranslation();

  function CounterS(props) {
    return (
      <div class="col-md-4">
        <div class="d-flex align-items-center justify-content-center">
          <div class="counter counter-xlarge fw-bolder">
            <span
              data-from={props.from}
              data-to={props.to}
              data-refresh-interval="10"
              data-speed="1600"
            ></span>
          </div>
          <span class="ms-2">
            {props.title1}
            <br />
            {props.title2}
          </span>
        </div>
      </div>
    );
  }
  function Renderskills() {
    let skills = [
      { skill: "About_Item1", icon: appreciation },
      { skill: "About_Item2", icon: creativeexpert },
      { skill: "About_Item3", icon: softwreengineer },
      { skill: "About_Item4", icon: teamspirit },
      { skill: "About_Item5", icon: ideas },
      { skill: "About_Item6", icon: typing },
      { skill: "About_Item7", icon: experiencedesign },
      { skill: "About_Item8", icon: process },
    ];
    return (
      <div className="row justify-content-center">
        {skills.map((skill) => (
          <div class="col-md-3 col-6 p-2" data-animate="fadeInUp">
            <div class="feature-box fbox-center fbox-plain p-3  bg-light border border-color border-width-3 mx-0">
              <div class="fbox-icon mb-4">
                <img src={skill.icon} alt="skill" />
              </div>
              <div class="fbox-content">
                <h4 className="color">{t(skill.skill)}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      <PageHead
        smtitle={t("About_us")}
        title={t("About_Description_1")}
        subtitle={t("About_Description_2")}
      />
      <section className="section bg-dark m-0">
        <div class="container mb-6">
          <div class="center">
            {/* <img src={teamimg} alt="team" className="m-auto" /> */}
          </div>

          <div
            className="row justify-content-center pb-6"
            data-animate="fadeInUp"
          >
            <div className="col-md-5 dark">
              <HeadingBlock
                className="text-light "
                title={t("About_Description_3")}
                subtitle={t("About_Description_2")}
                subtitleadclass="text-light op-05 text-size-md"
              />
            </div>
            <div className="col-md-7 m-auto">
              <p
                className="lead text-light ls1 text-size-md m-auto"
                style={{ lineHeight: "40px" }}
              >
                <p>
                  {t("About_Description_4")}
                </p>
                <p>
                 {t("About_Description_5")}
                </p>
                <p></p>
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row col-mb-30 color">
                <CounterS
                  title1={t("About_Description")}
                  title2="Experiences"
                  from="0"
                  to="10"
                />
                <CounterS
                  title1="+Projects"
                  title2={t("About_Description1")}
                  from="0"
                  to="15"
                />
                <CounterS
                  title1={t("About_Description3")}
                  title2={t("About_Description2")}
                  from="20"
                  to="100"
                />
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </div>

        <div className="container pt-lg-6">
          <HeadingBlock
            className="text-light center m-auto my-6"
            title={t("About_Description_7")}
            subtitle={t("About_Description_8")}
            subtitleadclass="text-light op-05 text-size-md"
          />
          <Renderskills />
          <div className="row dark topmargin-lg" data-animate="fadeInUp">
            <div className="col-md-6 order-1 order-md-2 m-auto">
              <HeadingBlock
                className="text-light m-auto my-6"
                title={t("Team_About")}
                subtitle={t("Team_About_1")}
                subtitleadclass="text-light op-05 text-size-md"
              />
            </div>
            <div className="col-md-6 order-md-1 order-2 m-auto pt-md-6">
              <p
                className="lead text-light ls1 m-auto"
                style={{ lineHeight: "40px" }}
              >
                {t("About_Description_6")}
              </p>
            </div>
          </div>
          <div
            className="row justify-content-center pt-6"
            data-animate="fadeIn"
          >
            {team.map((member) => (
              <div class="col-6 col-lg-3 p-3 p-xl-4">
                <div
                  style={{
                    content: "",
                    position: "relative",
                    left: "0",
                    top: "0",
                    width: "100%",
                    boxShadow: "6px 6px 0 0 #117ACA",
                  }}
                >
                  <div class="team card shadow">
                    <div class="team-image bg-color">
                      <img
                        src={member.image}
                        alt={member.name}
                        style={{
                          aspectRatio: "1/1",
                          objectFit: "cover",
                          ZIndex: "5",
                        }}
                      />
                      <div class="">
                      {/*<div
                          class="bg-overlay-content align-items-end animated fadeOut"
                          data-hover-animate="fadeIn"
                          data-hover-speed="400"
                          style={{
                            animationDuration: "400ms",
                            backgroundColor: "rgb(17, 122, 202, 0.8)",
                          }}
                        >
                        </div>*/}
                        {/*<div
                          class="bg-overlay-bg animated fadeOut"
                          data-hover-animate="fadeIn"
                          style={{ animationDuration: "600ms" }}
                      ></div>*/}
                      </div>
                    </div>
                    <div class="team-desc">
                      <div class="team-title">
                        <h5 className="m-0">{member.name}</h5>
                        <p class="color mt-2 mb-4">{t(member.role)}
                        {t(member.role2)}
                        {t(member.role3)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <CallToAction />
    </>
  );
}
