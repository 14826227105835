import dronek from "../images/clients/dronek.png";
import Esmerra from "../images/clients/Esmerra-website.png";
import mirage from "../images/clients/Mirage-leather.png";
import bedford from "../images/clients/1.png";
import immolux from "../images/clients/immolux.png";
import aim from "../images/clients/aim.png";
import Kemenani from "../images/clients/Kemenani-website.png";
import Dicarlo from "../images/clients/dicarloperfumes.png";
import Petitours from "../images/clients/Ours.png";
import Capital from "../images/clients/Afrique.png";


export const projects = [
  {
    id: 1,
    title: "WestchesterNYHomes",
    service: "Service_Design_work",
    description:"bedfordnyhomes_description_work",
    features: ["Frontend", ["Frontend", "React", "Bootstrap"],],
    image: bedford,
    orderclass: true,
    url:"https://WestchesterNYHomes.com/",
  },
  {
    id: 2,
    title: "dronek.net",
    service: "Service_Web_work",
    description1:"dronek_description1_work",
    features: ["Frontend", "Backend"],
    image: dronek,
    orderclass: false,
    url:"https://dronek.net/",
  },
  {
    id: 3,
    title: "mirage-leather.com",
    service: "Service_Web_work",
    description2:"mirage_Leather_description2_work",
    features: ["Frontend", "Backend", "PHP"],
    image: mirage,
    orderclass: true,
    url:"https://mirage-Leather.com/",
  },
  {
    id: 4,
    title: "immoluxsarl.com",
    service: "Service_Design_work",
    description3:"immoluxsarl_description3_work",
    features: ["Frontend", ["Frontend", "React", "Bootstrap"],],
    image: immolux,
    orderclass: false,
    url:"https://immoluxsarl.com/",
  },
  {
    id: 5,
    title: "aimothers.org",
    service: "Service_Design_work",
    description4:"aimothers_description4_work",
    features:  ["Frontend", "React", "Bootstrap"],
    image: aim,
    orderclass: true,
    url:"https://aimothers.org/",
    
  },
  {
    id: 6,
    title: "esmerra.com",
    service: "Service_Design_work",
    description5: "esmerra_description5_work",
    features: ["Frontend", "Backend"],
    image: Esmerra,
    orderclass: true,
    url:"https://esmerra.com/",
  },
  {
    id: 7,
    title: "kemenani.com",
    service: "Service_Design_work",
    description6: "kemenani_description6_work",
    features: ["Frontend", "Backend"],
    image: Kemenani,
    orderclass: true,
    url:"https://kemenani.com/",
  },
  {
    id: 8,
    title: "3petitours.com",
    service: "Service_Web_work",
    description7: "Ours_description7_work",
    features:  ["Frontend", "React", "Bootstrap"],
    image: Petitours,
    orderclass: true,
    url:"https://3petitsours.com/",
  },
  {
    id: 9,
    title: "dicarloperfumes.com",
    service: "Service_Web_work",
    description8: "Dicarlo_description8_work",
    features:  ["Frontend", "React", "Bootstrap"],
    image: Dicarlo,
    orderclass: true,
    url:"https://dicarloperfumes.com/",
  },
  {
  id: 10,
  title: "capitalafricanetwork .com",
  service: "Service_Web_work",
  description9: "Capital_description9_work",
  features:  ["Frontend", "React", "Bootstrap"],
  image: Capital,
  orderclass: true,
  url:"https://capitalafricanetwork.com/",
},
];
