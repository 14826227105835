import { Link } from "react-router-dom";
import patternbg from "../images/pattern-randomized.svg";
import { useTranslation } from "react-i18next";
import "../pages/Home/i18n";


export default function CallToAction(props) {
  const { t } = useTranslation();
  return (
    <section
      class="section bg-dark m-0 p-0"
      style={{ backgroundImage: `url(${patternbg})` }}
    >
      <div class="container py-6">
        <div className="row align-items-center justify-content-center">
          <div class="col-md-7 mb-5 mb-lg-0 ">
            <h5 class="font-body h4 mb-3 color">{t("Action_description_1")}</h5>
            <h2 class="h1 mb-0 text-light">
             {t("Action_description_2")}
            </h2>
          </div>
          <div class="col-md-5 topmargin" data-animate="bounceIn">
            <div
              class=""
              style={{
                content: "",
                position: "relative",
                left: "0",
                top: "0",
                width: "250px",
                boxShadow: "4px 4px 0 0 #117ACA",
              }}
            >
              <Link exact to="/contact" className="">
                <div className=" center w-100 border border-width-2 border-dark p-3 bg-light h-bg-dark color">
                  {t("Action_description_3")}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
