import patternbg from "../../images/pattern-randomized.svg";

export default function PageHead(props) {
  return (
    <section
      id="content"
      style={{
        backgroundImage: `url(${patternbg})`,
      }}
      className="include-header min-vh-25 bg-dark m-0"
    >
      <div className="bg-overlay" style={{ zIndex: "0" }}></div>
      <div
        className="container justify-content-center align-items-end h-100"
        style={{ zIndex: "1" }}
      >
        <div className="center pt-6 p-md-6 dark">
          <div class="heading-block pt-md-6">
            <h5 class="color">
              <span class="op-08 h4">{props.smtitle}</span>
            </h5>
            <h1 className="text-light" style={{ fontSize: "54px" }}>
              {props.title}
            </h1>
            <span class="text-light op-08">{props.subtitle}</span>
          </div>
        </div>
      </div>
    </section>
  );
}
