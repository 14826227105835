import profile from "../images/teamprofiles/Guillaum.jpg";
import ahmedprofile from "../images/teamprofiles/ahmeddaou.jpeg";
import ibrahimprofile from "../images/teamprofiles/ibrahimkoita.jpeg"; 

export const team = [
  {
    name: "Ahmed Daou",
    role: "About_role_Ahmed",
    image: ahmedprofile,
  },
  {
    name: "Ibrahim Koita",
    role2: "About_role_Ibrahim",
    image: ibrahimprofile,
  },
  {
    name: "Guillaume Cuny",
    role3: "About_role_Guillame",
    image: profile,
  },
 ];
