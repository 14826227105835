import {
  SiReact,
  SiNextdotjs,
  SiVuedotjs,
  SiNodedotjs,
  SiDjango,
  SiLaravel,
  SiKotlin,
  SiSwift,
  SiFlutter,
  SiMongodb,
  SiFirebase,
  SiRedis,
  SiMysql,
  SiPostgresql,
  SiAmazonaws,
  SiGooglecloud,
  SiMicrosoftazure,
  SiDigitalocean,
  SiDocker,
  SiKubernetes,
} from "react-icons/si";

export const technologies = [
  {
    title:"Service_description_Tech1",
    tools: {
      "React.Js" : <SiReact/>,
      "Next.Js" : <SiNextdotjs />,
      "Vue.Js" : <SiVuedotjs />,
      "Node.Js" : <SiNodedotjs />,
      "Django" : <SiDjango />,
      "Laravel" : <SiLaravel />,
    },
  },
  {
    title: "Service_description_Tech2",
    tools: {
      "Kotlin" : <SiKotlin />,
      "Swift" : <SiSwift />,
      "Flutter" : <SiFlutter />,
    }
  },
  {
    title: "Service_description_Tech3",
    tools: {
      "MongoDB" : <SiMongodb />,
      "Firebase" : <SiFirebase />,
      "Redis" : <SiRedis />,
      "MySQL" : <SiMysql />,
      "PostgreSQL" : <SiPostgresql />,
    }
  },
  {
    title: "Service_description_Tech4",
    tools: {
      "Amazon AWS" : <SiAmazonaws />, 
      "Google Cloud" : <SiGooglecloud />,
      "Microsoft Azure" : <SiMicrosoftazure />,
      "Digital Ocean" : <SiDigitalocean />,
      "Docker" : <SiDocker />,
      "Kubernetes" : <SiKubernetes />,
    }
  },
];
