/* eslint-disable no-dupe-keys */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import "../Contact";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          home_description_1: "your software partners for the Digital Age",
          home_description_2: "We deliver technology the diplomatic way!",
          home_description_3: "Know More",
          home_description_4: "What we do",
          home_description_5:
            "Consistently delivering the best in technology, we support businesses and startups in their journey to digital success by providing scalable, reliable and secure software and marketing solutions.",
          home_service: "Our Services",
          home_description_6: "Our Process",
          home_description_7: "How we work",
          home_description_software: "Software Development",
          home_description_8:
            "We're experts in end to end software development that fuel business growth. From kickoff to launch, from mobile apps to enterprise-scale platforms, launch efficient, robust, user-loved solutions.",
          home_description_9: "Our Methodology",
          home_description_10: "Agile collaboration all the way",
          home_description_11:
            "Our software development process follows the Disciplined Agile Delivery (DAD) methodology, which helps us fulfill the project scope successfully. Combined with Scrum, were able to provide a fast time to market and fine-tune features while ensuring alignment between strategy, design, and development.",
          home_description_12: "Our Tools and Technologies",
          home_description_13: "The right tools for the right job",
          home_description_14:
            "Every application is unique and so is the journey building it. We customize our process and tools to best suit your needs. Time and experience inform our go-to selection of technologies. Their flexibility, growth orientation, stability and rapidly evolving nature are unparalleled.",
          home_description_15: " Our Clients",
          home_description_16: "Discover our clients and their stories",
          home_description_17:
            " We’re proud to have worked with some of the world’s most iconic  brands. We’ve helped them build their digital presence and have helped them grow their business.",
          Action_description_1: "Ready to get started ?",
          Action_description_2: "Tell us about your project and we'll get back to you",
          Action_description_3: "Let's get in touch",


          //Services
          Service_title1: "what we do",
          Service_title2: "Services",
          Service_title3:
            "Discover our personalized services, from software development to digital marketing",
          Service_description1: "Software Development",
          Service_description2: "Desktop, Web and Mobile Application Development",
          Service_description3:
            "We are a team of experienced software developers, designers and project managers. We have a wide range of skills and experience in the field of software development.",
          Service_description4: "Digital Marketing",
          Service_description5: "Social Media, Advertising, and Content Marketing",
          Service_description6:
            "We provide a 'one-stop' solution for all your digital marketing needs from corporate to advertising, advertising and content marketing.",
          Service_description7: "Graphic Design",
          Service_description8: "Visual Identity, Advertising and Motion Graphics",
          Service_description9:
            "A successful brand image is the combination of a strong visual identity and a strong visual language. We help you create a strong, unique and memorable visual identity.",
          Service_description10: "Brand Identity",
          Service_description11: "Motion Graphics & Animations",
          Service_description_Tech1: "Web Development",
          Service_description_Tech2: "Mobile App Development",
          Service_description_Tech3: "Database",
          Service_description_Tech4: "Infrastructure",

          title_Strategy: "Ideation & Strategy",
          title1_Design: "UX / UI Design",
          title2_Development: "Development & Rapid Prototyping",
          title3_Maintenance: "Launch & Maintenance",

          Service_description1_Brand:
            "We create a unique and memorable brand identity.",
          Service_description2_Graphics:
            "Visually appealing and engaging graphics and animations.",

          Service_features_Graphics_Design: "Graphics Design",
          Service_features_Animation: "Animation",
          Service_features_Video_Animation: "Animation video",
          Service_features_Motion_Graphics: "Motion Graphics",

          Service_features_Logo_Design: "Logo Creation",
          Service_features_Identity: "Identity",
          Service_features_Packaging: "Packaging, wrapping",
          Service_Business_Cards: "Business Cards",
          Service_features_Brochures: "Brochures",
          Service_features_Posters: "Posters",
          Service_features_Flyers: "Flyers",
          Service_features_Stationery: "Stationery",

          Service_Process_Strategy:
            "We work with you to understand the problem you’re trying to solve and the digital product solution to that problem",
          Service_Process_Design:
            "Based on app strategy we created, we'll move to mapping the flow of your app. We'll also create wireframes and mockups",
          Service_Process_Development:
            "We'll build your app from scratch, or we'll use our expertise to help you prototype your app",
          Service_Process_Maintenance:
            "After your app is ready, we'll launch it on the web and deploy it to the cloud for you",
          Service_description_Marketing:
            "Customized digital marketing solutions to help businesses grow and thrive in the digital world.",
          Service_description2_Promotion:
            "Desiging and developing advertising campaigns and publishing content that target audience finds valuable.",
          Service_description3_Management:
            "Leverage the power of social media to drive engagement, increase brand value and generate results.",

          Service_description15:
            "Digital Marketing",
          service_bullet_Website_Redesign: "Website Redesign",
          service_bullet_SEO: "SEO",
          service_bullet_Analytics: "Analytics",
          service_bullet_Social_Media: "Social Media",
          service_bullet_Content_Marketing: "Content Marketing",
          service_bullet_Email_Marketing: "Email Marketing",
          Service_description16:
            "Advertising & promotion",
          service_bullet_Advertising: "Advertising",
          service_bullet_Web_Content: "Web Content",
          service_bullet_Blog_Articles: "Blog Articles",
          service_bullet_Social_Posts: "Social Posts",
          service_bullet_Campaigns: "Campaigns",
          service_bullet_Press_Releases: "Press Releases",
          Service_description17:
            "Social Media Management",
          service_bullet_Community_Management: "Community Management",
          service_bullet_Campaign_targeting: "Campaign targeting",
          service_bullet_Social: "Social",
          service_bullet_Account_Optimization: "Account Optimization",

          Service_Design_work: "Web Design",
          Service_Web_work: "Web Development",

          "Service_description1_web":
            "Web development is the work involved in developing a web site for the Internet (World Wide Web) or an intranet (a private network).",
          "Service_description2_mobile":
            "Mobile app development is the process of converting a software application into a mobile application.",
          "Service_description3_Software":
            "Custom software development is the process of creating a custom software application for enterprise",

          Service_description12: "Web Development",
          "softwaredev_Design": "Design",
          "softwaredev_Development": "Development",
          "softwaredev_Testing": "Testing",
          "softwaredev_Hosting": "Hosting",
          "softwaredev_Maintenance": "Maintenance",
          "softwaredev_Security": "Security",
          "softwaredev_Analytics": "Analytics",
          "softwaredev_Performance": "Performance",
          "softwaredev_SEO": "SEO",

          digitalization_description: "Digitalization",
          digitalization_description2:"Digital Marketing",
          digitalization_description3:"Cloud Services",

          Service_description13: "Mobile Development",
          "softwaredev_UI/UX Design": "UI/UX Design",
          "softwaredev_iOS": "iOS",
          "softwaredev_Android": "Android",
          "softwaredev_Cross Platform": "Cross Platform",
          "softwaredev_Testing": "Testing",
          "softwaredev_Maintenance": "Maintenance",
          "softwaredev_Security": "Security",
          "softwaredev_Analytics": "Analytics",
          "softwaredev_Performance": "Performance",
          "softwaredev_Landing Page": "Landing Page",
          Service_description14: "Software Development",
          "softwaredev_Frontend": "Frontend",
          "softwaredev_Backend": "Backend",
          "softwaredev_Database": "Database",
          "softwaredev_Testing": "Testing",
          "softwaredev_Maintenance": "Maintenance",
          "softwaredev_Security": "Security",
          "softwaredev_Performance": "Performance",
          "softwaredev_Cloud_Services": "Cloud Services",

          Service_description1_Brand:
            "We create a brand identity that is unique and memorable.",
          Service_description2_Graphics:
            "Graphics and animation that are visually appealing and engaging.",

          Services_features1: "IT Consulting",
          Services_features2: "Cloud Migration",
          Services_features3: "Software Development",
          Services_features4: "ERP, CRM Integration",
          "Landing Page": "Landing Page",

          Service_title_1: "software development",
          "Service_bullet1": "Web development",
          "Service_bullet2": "Mobile App",
          "Service_bullet3": "Custom Software",
          Service_title_2: "Digital Marketing",
          "Service_bullet4": "Digital Marketing",
          "Service_bullet5": "Advertising & promotion ",
          "Service_bullet6": "Social Media",
          Service_title_3: "Graphics Design",
          "Service_bullet7": "Brand Identity",
          "Service_bullet8": "Print Design",
          "Service_bullet9": "Motion Graphics & Animation",

          //HeaderNavigation
          Language:"Language",
          English:"English",
          French:"French",

          //Footer
          footer_text: "Your Software Partner for the digital age.",
          footer_text1:
            "We help businesses and startups to build their digital presence.",
          footer_text2:
            "Copyrights &copy; 2022 All Rights Reserved by Diplomatech.",
          footer_text3: "Privacy Policy",
          footer_text4: "Explore",
          Services_footer: "Services",
          HeaderNav_Home: "Home",
          HeaderNav_Services: "Services",
          HeaderNav_Work: "Projects",
          HeaderNav_About: "About",
          HeaderNav_Contact: "Contact",

          //Projects
          Work_title1: "Our Projects",
          Work_title2: "Project",
          Work_title3: "Some of our innovative projects",
          bedfordnyhomes_description_work:
            "Bedfordnyhomes.com is a real estate website for rentinng and selling homes in Bedford.",
          dronek_description1_work:
            "Dronek.net is the website for the Dronek company, an agricultural and forestry expertise company. Dronek is specialized providing drone data and services to farmers and agroforestry companies.",
          "mirage_Leather_description2_work":
            "A leather goods and accessories ecommerce website for Mirage Leather Company.",
          immoluxsarl_description3_work:
            "immoluxsarl.com is a construction and home renovation website for immolux SARL.",
          aimothers_description4_work:
            "AIM is a website for the Active Intervention for Mothers, an charity organization to help reduice child mortality.",
          esmerra_description5_work:
            "An ecommerce website for Lily Madisson, a fashion designer.",
          kemenani_description6_work: "An ecommerce website for Kemenani",
          Ours_description7_work: "3petits Ours is a kindergarten site",
          Dicarlo_description8_work: "Dicarlo is a perfumery site",
          Capital_description9_work: "Capital Africa Network is a Business site",

          //(About)
          About_us: "About Us",
          About_Description_1: "Who are we?",
          About_Description_2:
            "Discover our innovative approach to bringing your ideas to life.",
          About_Description_3: "Who are we?",
          About_Description_4:
            "Consider us as your software development partner and your marketing strategist. we provide you with the best software development services and marketing solutions to help you  achieve your business goals.",
          About_Description_5:
            "We are a team of creative and passionate engineers, designers and business strategists who believe in the power of technology. Our vision is to create a world where every individual is empowered to realize their full potential.",
          About_Description_6:
            "At Diplomatech, each member of our team is a unique individual who has a passion for what they do. Being passionate about what we do means we put our hearts into it. We have strong opinions, but being professional and practical means that our passion drives us. It never distracts us from what we want most: a successful outcome.",
          About_Description_7: "Why choose us",
          About_Description_8:
            "We have developed a unique approach and skills to boost your digital presence.",
          About_Description: "+ Years of",
          About_Description1: "Complete",
          About_Description2: "% Satisfied",
          About_Description3: "Customer",
          About_Description4: "Complete",
          Team_About: "Our team",
          Team_About_1:
            "We are a team of creative and passionate people who love what we do.",
          About_Item1: "Passionate Team",
          About_Item2: "Creative Expert",
          About_Item3: "Qualified Engineer",
          About_Item4: "Team Player",
          About_Item5: "Innovative Ideas",
          About_Item6: "Smooth Communicator",
          About_Item7: "Experienced Designer",
          About_Item8: "Adjusted process",

         

          About_role_Ahmed: "Chief Executive",
          About_role_Ibrahim: "Chief Operating Officer",
          About_role_Guillame: "Marketing Director",
          //Contact
          "Contact_Description": "Contact Us",
          "Contact_Description1":
           "We’re here to transform your ideas into business solutions and drive execution from concept to launch.",
       
           About_Item_Hours: "Hours",
           About_Item_Phone: "Phone",
           About_Item_Address: "Address",
           About_Item_Monday: "Monday",
           About_Item_Friday: "Friday",
          },
      },
      fr: {
        translations: {
          //Home 
          home_description_1: "vos partenaires logiciels pour l'ère numérique",
          home_description_2: "Nous livrons la technologie de manière diplomatique !",
          home_description_3: "Savoir plus",
          home_description_4: "Ce que nous faisons",
          "home_service": "Nos services",
          home_description_5:
            "En fournissant constamment le meilleur de la technologie, nous soutenons les entreprises et les startups dans leur cheminement vers le succès numérique en fournissant des logiciels et des solutions marketing évolutifs, fiables et sécurisés.",
          "home_description_6": "Notre processus",
          "home_description_7": "Comment nous travaillons",
          home_description_8:
            "Nous sommes des experts dans le développement de logiciels de bout en bout qui alimentent la croissance des entreprises. Du démarrage au lancement, des applications mobiles aux plateformes à l'échelle de l'entreprise, lancez des solutions efficaces, robustes et appréciées des utilisateurs.",
          home_description_9: "Notre Méthodologie",
          home_description_10: "Collaboration agile jusqu'au bout",
          home_description_11:
            "Notre processus de développement logiciel suit la méthodologie Disciplined Agile Delivery (DAD), qui nous aide à remplir avec succès la portée du projet. Combiné à Scrum, nous avons pu fournir un délai de mise sur le marché rapide et affiner les fonctionnalités tout en assurant l'alignement entre la stratégie, la conception, et développement.",

          home_description_12: "Nos outils et technologies",
          home_description_13: "Les bons outils pour le bon travail",
          home_description_14:
            "Chaque application est unique, tout comme le parcours de sa création. Nous personnalisons notre processus et nos outils pour répondre au mieux à vos besoins. Le temps et l'expérience informent notre sélection de technologies. Leur flexibilité, leur orientation vers la croissance, leur stabilité et leur nature en évolution rapide sont inégalées. .",

          home_description_15: " Nos Clients",
          home_description_16: "Découvrez nos clients et leurs histoires",
          home_description_17:
            " Nous sommes fiers d'avoir travaillé avec certaines des marques les plus emblématiques au monde. Nous les avons aidés à développer leur présence numérique et à développer leur activité. ",
          Action_description_1: "Prêt à démarrer ?",
          Action_description_2:
            "Dites-nous ce que vous voulez et nous reviendrons vers vous.",
          Action_description_3: "Entrons en contact",

          Service_description_Tech1: "Développement web",
          Service_description_Tech2: "Développement d'Application Mobile",
          Service_description_Tech3: "Base de données",
          Service_description_Tech4: "Infrastructure",

          Service_Process_Strategy: "Idéation et stratégie",
          Service_Process_Design: "Conception UX/UI",
          Service_Process_Development: "Développement & Prototypage Rapide",
          Service_Process_Maintenance: "Lancement & Entretien",
          //Services 
          Service_title1: "ce que nous faisons",
          Service_title2: "Services",
          Service_title3:
            "Découvrez nos services personnalisés, du développement logiciel au marketing digital",

          Service_description1: "Développement de logiciels",
          Service_description2: "Développement d'applications de bureau, Web et mobiles",
          Service_description3:
            "Nous sommes une équipe de développeurs de logiciels, de concepteurs et de chefs de projet expérimentés. Nous avons un large éventail de compétences et d'expérience dans le domaine du développement de logiciels.",
          Service_description4: "Marketing numérique",
          Service_description5: "Réseaux sociaux, publicité et marketing de contenu",
          Service_description6:
            "Nous fournissons une solution unique pour tous vos besoins en marketing numérique, de l'entreprise à la publicité, en passant par la publicité et le marketing de contenu.",
          Service_description7: "Conception graphique",
          Service_description8: "Identité visuelle, publicité et animations graphiques",
          Service_description9:
            "Une image de marque réussie est la combinaison d'une identité visuelle forte et d'un langage visuel fort. Nous vous aidons à créer une identité visuelle forte, unique et mémorable.",
          Service_description10: "Identité de la marque",
          Service_description11: "Graphiques animés et animations",
          Service_description_Tech1: "Développement Web",
          Service_description_Tech2: "Développement d'applications mobiles",
          Service_description_Tech3: "Base de données",
          Service_description_Tech4: "Infrastructure",

          title_Strategy: "Idéation et stratégie",
          title1_Design: "Conception UX/UI",
          title2_Development: "Développement et prototypage rapide",
          title3_Maintenance: "Lancement et maintenance",

          Service_description1_Brand:
            "Nous créons une identité de marque unique et mémorable.",
          Service_description2_Graphics:
            "Des graphismes et des animations visuellement attrayants et attrayants.",

          Service_features_Graphics_Design: "Conception graphique",
          Service_features_Animation: "Animation",
          Service_features_Video_Animation: "Vidéo d'animation",
          Service_features_Motion_Graphics: "Graphiques animés",

          Service_features_Logo_Design: "Création de logo",
          Service_features_Identity: "Identité",
          Service_features_Packaging: "Emballage, emballage",
          Service_Business_Cards: "Cartes de visite",
          Service_features_Brochures: "Brochures",
          Service_features_Posters: "Affiches",
          Service_features_Flyers: "Flyers",
          Service_features_Stationery: "Papeterie",

          Service_Process_Strategy:
            "Nous travaillons avec vous pour comprendre le problème que vous essayez de résoudre et la solution de produit numérique à ce problème",
          Service_Process_Design:
            "Sur la base de la stratégie d'application que nous avons créée, nous passerons à la cartographie du flux de votre application. Nous créerons également des wireframes et des maquettes",
          Service_Process_Development:
            "Nous créerons votre application à partir de zéro, ou nous utiliserons notre expertise pour vous aider à prototyper votre application",
          Service_Process_Maintenance:
            "Une fois votre application prête, nous la lancerons sur le Web et la déploierons sur le cloud pour vous",
          Service_description_Marketing:
            "Des solutions de marketing numérique personnalisées pour aider les entreprises à se développer et à prospérer dans le monde numérique.",
          Service_description2_Promotion:
            "Concevoir et développer des campagnes publicitaires et publier du contenu que le public cible trouve précieux.",
          Service_description3_Management:
            "Exploitez la puissance des médias sociaux pour stimuler l'engagement, augmenter la valeur de la marque et générer des résultats.",

          Service_description15:
            "Le marketing numérique",
          service_bullet_Website_Redesign: "Refonte du site Web",
          service_bullet_SEO: "SEO",
          service_bullet_Analytics: "Analytics",
          service_bullet_Social_Media: "Réseaux sociaux",
          service_bullet_Content_Marketing: "Marketing de contenu",
          service_bullet_Email_Marketing: "Marketing par e-mail",
          Service_description16:
            "Publicité et promotion",
          service_bullet_Advertising: "Publicité",
          service_bullet_Web_Content: "Contenu Web",
          service_bullet_Blog_Articles: "Articles de blog",
          service_bullet_Social_Posts: "Messages sociaux",
          service_bullet_Campaigns: "Campagnes",
          service_bullet_Press_Releases: "Communiqués de presse",
          Service_description17:
            "Gestion des réseaux sociaux",
          service_bullet_Community_Management: "Gestion de la communauté",
          service_bullet_Campaign_targeting: "Ciblage de la campagne",
          service_bullet_Social: "Réseaux sociaux",
          service_bullet_Account_Optimization: "Optimisation du compte",

          Service_Design_work: "Conception Web",
          Service_Web_work: "Développement Web",

          digitalization_description: "Numérisation",
          digitalization_description2:"Marketing numérique", 
          digitalization_description3:"Services Cloud",

          "Service_description1_web":
            "Le développement Web est le travail impliqué dans le développement d'un site Web pour Internet (World Wide Web) ou un intranet (un réseau privé).",
          "Service_description2_mobile":
            "Le développement d'applications mobiles est le processus de conversion d'une application logicielle en une application mobile.",
          "Service_description3_Software":
            "Le développement de logiciels personnalisés est le processus de création d'une application logicielle personnalisée pour l'entreprise",

          Service_description12: "Développement Web",
          "softwaredev_Design": "Conception",
          "softwaredev_Development": "Développement",
          "softwaredev_Testing": "Test",
          "softwaredev_Hosting": "Hébergement",
          "softwaredev_Maintenance": "Maintenance",
          "softwaredev_Security": "Sécurité",
          "softwaredev_Analytics": "Analytique",
          "softwaredev_Performance": "Performances",
          "softwaredev_SEO": "SEO",

          Service_description13: "Développement mobile",
          "softwaredev_UI/UX Design": "UI/UX Design",
          "softwaredev_iOS": "iOS",
          "softwaredev_Android": "Android",
          "softwaredev_Cross Platform": "Cross Platform",
          "softwaredev_Testing": "Test",
          "softwaredev_Maintenance": "Maintenance",
          "softwaredev_Security": "Sécurité",
          "softwaredev_Analytics": "Analytique",
          "softwaredev_Performance": "Performances",
          "softwaredev_Landing Page": "Page de destination",
          Service_description14: "Développement de logiciels",
          "softwaredev_Frontend": "Frontend",
          "softwaredev_Backend": "Backend",
          "softwaredev_Database": "Base de données",
          "softwaredev_Testing": "Test",
          "softwaredev_Maintenance": "Maintenance",
          "softwaredev_Security": "Sécurité",
          "softwaredev_Performance": "Performances",
          "softwaredev_Cloud_Services": "Cloud Services",

          Service_description1_Brand:
            "Nous créons une identité de marque unique et mémorable.",
          Service_description2_Graphics:
            "Des graphismes et des animations visuellement attrayants et engageants.",

          Services_features1: "Conseil informatique",
          Services_features2: "Migration vers le cloud",
          Services_features3: "Développement de logiciels",
          Services_features4: "Intégration ERP, CRM",
          "Page de destination": "Page de destination",

          Service_title_1: "Développement de logiciels",
          "Service_bullet1": "Développement Web",
          "Service_bullet2": "Application mobile",
          "Service_bullet3": "Logiciel personnalisé",
          Service_title_2: "Marketing numérique",
          "Service_bullet4": "Marketing numérique",
          "Service_bullet5": "Publicité et promotion",
          "Service_bullet6": "Médias sociaux",
          Service_title_3: "Conception graphique",
          "Service_bullet7": "Identité de marque",
          "Service_bullet8": "Conception d'impression",
          "Service_bullet9": "Graphismes animés et animation",

          //HeaderNavigation
          Language: "Langue",
          English: "Anglais",
          French: "Français",

          //Footer   
          footer_text: "Votre partenaire logiciel pour l'ère numérique.",
          footer_text1:
            "Nous aidons les entreprises et les startups à construire leur presse numérique.",
          footer_text2:
            "Copyrights &copy; 2022 Tous droits réservés par Diplomatech.",
          footer_text3: "Politique de confidentialité",
          footer_text4: "Explore",
          Services_footer: "présentation de Services",
          HeaderNav_Home: "Acceuil",
          HeaderNav_Contact: "Contact",
          HeaderNav_Services: "Services",
          HeaderNav_Work: "Projets",
          HeaderNav_About: "À propos",

          //Projects 
          Work_title1: "Nos projets",
          Work_title2: "Travail",
          Work_title3: "Quelques projets innovants",
          bedfordnyhomes_description_work:
            "Une application de gestion de maison pour les habitants de Bedford, New York.",
          dronek_description1_work:
            "Dronek.net est le site Internet de la société Dronek, une société d'expertise agricole et forestière. Dronek est spécialisée dans la fourniture de données et de services de drones aux agriculteurs et aux entreprises agroforestières.",
          mirage_Leather_description2_work:
            "Un site e-commerce de maroquinerie et d'accessoires pour Mirage Leather Company.",
          immoluxsarl_description3_work:
            "immoluxsarl.com est un site de construction et de rénovation pour immolux SARL.",
          aimothers_description4_work:
            "AIM est un site Web pour l'Active Intervention for Mothers, une organisation caritative qui aide à réduire la mortalité infantile.",
          esmerra_description5_work:
            "Un site de commerce électronique pour Lily Madisson, une créatrice de mode.",
          kemenani_description6_work: "Un site e-commerce pour Kemenani",
          Ours_description7_work: "3petits Ours est un site Crèche Maternelle",
          Dicarlo_description8_work: "Dicarlo est un site de parfumerie",
          Capital_description9_work: "Capital Africa Network est un site de Buiness",

          //(About)
          About_Description_1: "Qui sommes-nous ?",
          About_us: "À propos de nous",
          About_Description_2:
            "Découvrez notre approche innovante pour donner vie à vos idées.",
          About_Description_3: "Qui sommes-nous ?",
          About_Description_4:
            "Considérez-nous comme votre partenaire de développement logiciel et votre stratège marketing. Nous vous fournissons les meilleurs services de développement logiciel et solutions marketing pour vous aider à atteindre vos objectifs commerciaux.",
          About_Description_5:
            "Nous sommes une équipe d'ingénieurs, de designers et de stratèges commerciaux créatifs et passionnés qui croient au pouvoir de la technologie. Notre vision est de créer un monde où chaque individu est habilité à réaliser son plein potentiel.",
          About_Description_6:
            "Chez Diplomatech, chaque membre de notre équipe est une personne unique qui a une passion pour ce qu'il fait. Être passionné par ce que nous faisons signifie que nous y mettons tout notre cœur. Nous avons des opinions bien arrêtées, mais être professionnel et pratique signifie que notre passion nous motive. Il ne nous détourne jamais de ce que nous voulons le plus : un résultat réussi.",
          About_Description_7: "Pourquoi nous choisir",
          About_Description_8:
            "Nous avons développé une approche et des compétences uniques pour booster votre présence digitale.",
          About_Description: "+ années d'",
          About_Description1: "Complète",
          About_Description2: " % Satisfait",
          About_Description3: "Client",
          About_Description4: "Complètes",
          Team_About: "Notre équipe",
          Team_About_1:
            "Nous sommes une équipe de personnes créatives et passionnées qui aiment ce que nous faisons.",
          About_Item1: "Équipe passionnée",
          About_Item2: "Expert créatif",
          About_Item3: "Ingénieur qualifié",
          About_Item4: "Joueur d'équipe",
          About_Item5: "Idées innovantes",
          About_Item6: "Communicateur fluide",
          About_Item7: "Concepteur expérimenté",
          About_Item8: "Processus ajusté",

         

          About_role_Ahmed: "Directeur général",
          About_role_Ibrahim: "Directeur des Opérations",
          About_role_Guillame: "Directeur Marketing",
          //Contact
          "Contact_Description": "Contactez-nous",
          "Contact_Description1": "Nous sommes là pour transformer vos idées en solutions commerciales et piloter l'exécution du concept au lancement.",
        
          About_Item_Hours: "Heures",
          About_Item_Phone: "Téléphone",
          About_Item_Address: "Adresse",
          About_Item_Monday: "Lundi",
          About_Item_Friday: "vendredi",
        },
      },
    },

    fallbackLng: "fr",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
