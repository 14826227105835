export const graphicdesign = [
  {
    title: "Service_description10",
    description1: "Service_description1_Brand",
    image:
      "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/ffffff/external-corporate-editorial-design-flatart-icons-outline-flatarticons.png",
    features: [
      "Service_features_Logo_Design",
      "Service_features_Identity",
      "Service_features_Packaging",
      "Service_Business_Cards",
      "Service_features_Brochures",
      "Service_features_Posters",
      "Service_features_Flyers",
      "Service_features_Stationery",
    ],
  },
  {
    title:"Service_description11",
    description2:
    "Service_description2_Graphics",  
    image: "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/ffffff/external-design-editorial-design-flatart-icons-outline-flatarticons-3.png",
    features: [
      "Service_features_Graphics_Design",
      "Service_features_Animation",
      "Service_features_Video_Animation",
      "Service_features_Motion_Graphics",
    ],
  },
];