import PageHead from "../../components/PageHead";
import { softwaredev } from "../../data/softwaredev";
import softwareDevelopemnt from '../../images/software-development.svg';
import digitalmarketillu from "../../images/digitalmarketing.svg";
import graphicDesignillu from "../../images/brandidentity.png";
import { digitalmarketing } from "../../data/digitalmarketing";
import { graphicdesign } from "../../data/graphicdesign";
import CallToAction from "../../components/CallToAction";
import { useTranslation } from "react-i18next";
import "../Home/i18n";
  
export default function Services() {
  const { t } = useTranslation();
  return (
    <>
      <PageHead
        smtitle={t("Service_title1")}
        title={t("Service_title2")}
        subtitle={t("Service_title3")} 
      />
      <section className="bg-azure">
        <div className="row p-md-6 p-2 pt-3 m-0">
          <div className="col-lg-6 m-auto">
            <div className="col-xxl-8 ms-xxl-6">
              <div class="title-block">
                <h1>{t("Service_description1")}</h1>
                <span className="h4 op-06">
                 {t("Service_description2")}
                </span>
              </div>
              <p style={{ fontSize: "1.2rem" }}>
              {t("Service_description3")}
              </p>
            </div>
          </div>
          <div className="col-lg-6 m-0 p-0" data-animate="fadeInRight">
            <img
              src={softwareDevelopemnt}
              alt="software development"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="section bg-transparent">
          <div className="container p-0">
            <div className="row p-4 p-md-3">
              {softwaredev.map((service) => (
                <div
                  class="col-lg-4 col-md-6 d-flex pb-4"
                  data-animate="fadeInUp"
                >
                  <div
                    class="card"
                    style={{
                      content: "",
                      position: "relative",
                      left: "0",
                      top: "0",
                      width: "100%",
                      height: "100%",
                      boxShadow: "10px 10px 0 0 #211E1E",
                    }}
                  >
                    <div class="card-body p-2 p-xxl-5 shadow-lg bg-color border border-light border-width-2">
                      <div class="feature-box flex-column">
                        <div class="fbox-image mb-5 text-center">
                          <img
                            src={service.image}
                            alt={"Illustration"}
                            height="150"
                          />
                        </div>
                        <div class="fbox-content center">
                          <h3 class="text-dark">{t(service.title)}
                          </h3>
                          <p class="text-light">{t(service.description1)}
                          {t(service.description2)}
                          {t(service.description3)}
                          </p>
                        </div>
                        <ul class="iconlist row mt-4 mb-0">
                          {service.features.map((feature) => (
                            <li class="mb-2 col-6 text-light op-08">
                              <i class="icon-check text-smaller text-dark op-05"></i>
                              {t(feature)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark m-0 py-6">
        <div className="row p-md-6 p-2 pt-3 m-0">
          <div className="col-lg-6 m-auto order-md-2 order-1 row ps-xl-6">
            <div className="col-xxl-8 ">
              <div class="title-block">
                <h1 className="text-light">{t("Service_description4")}</h1>
                <span className="h4 text-light op-06">
                 {t("Service_description5")}
                </span>
              </div>
              <p className="text-light" style={{ fontSize: "1.2rem" }}>
              {t("Service_description6")}
              </p>
            </div>
          </div>
          <div
            className="col-lg-5 m-0 ps-xxl-6 order-md-1 order-2"
            data-animate="fadeInLeft"
          >
            <img
              src={digitalmarketillu}
              alt="software development"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="section bg-transparent m-0">
          <div className="container p-0">
            <div className="row p-4 p-md-3">
              {digitalmarketing.map((service) => (
                <div
                  class="col-lg-4 col-md-6 d-flex p-3"
                  data-animate="fadeInUp"
                >
                  <div
                    class="card"
                    style={{
                      content: "",
                      position: "relative",
                      left: "0",
                      top: "0",
                      width: "100%",
                      height: "100%",
                      boxShadow: "10px 10px 0 0 #117ACA",
                    }}
                  >
                    <div class="card-body p-2 p-xxl-5 shadow-lg bg-light border border-dark border-width-2">
                      <div class="feature-box flex-column">
                        <div class="fbox-image mb-5 text-center">
                          <img
                            src={service.image}
                            alt={"Illustration"}
                            height="150"
                          />
                        </div>
                        <div class="fbox-content center">
                          <h3 class="color">{t(service.title)}
                          </h3>
                          <p class="text-dark">{t(service.description)}
                          {t(service.description2)}
                          {t(service.description3)}
                          </p> 
                        </div>
                        <ul class="iconlist row mt-4 mb-0">
                          {service.features.map((feature) => (
                            <li class="mb-2 col-6 text-dark op-08">
                              <i class="icon-check text-smaller text-dark op-05"></i>
                              {t(feature)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-color mt-0 py-6">
        <div className="row p-md-6  pt-3 m-auto container ">
          <div className="col-lg-6 m-auto">
            <div className="">
              <div
                class="title-block"
                style={{ borderLeft: "7px solid azure" }}
              >
                <h1 className="text-dark">{t("Service_description7")}</h1>
                <span className="h4 text-dark op-06">
                {t("Service_description8")}
                </span>
              </div>
              <p className="text-dark" style={{ fontSize: "1.2rem" }}>
               {t("Service_description9")}
              </p>
            </div>
          </div>
          <div className="col-lg-6 m-auto" data-animate="fadeInRight">
            <img
              src={graphicDesignillu}
              alt="Graphic Illustration"
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
        <div className="section bg-transparent">
          <div className="container p-0">
            <div className="row justify-content-center p-4 p-md-3">
              {graphicdesign.map((service) => (
                <div class="col-md-4 d-flex p-3" data-animate="fadeInUp">
                  <div
                    class="card"
                    style={{
                      content: "",
                      position: "relative",
                      left: "0",
                      top: "0",
                      width: "100%",
                      height: "100%",
                      boxShadow: "10px 10px 0 0 azure",
                    }}
                  >
                    <div class="card-body p-5 shadow-lg bg-dark border border-color border-width-2">
                      <div class="feature-box flex-column">
                        <div class="fbox-image mb-5 text-center">
                          <img
                            src={service.image}
                            alt={"Illustration"}
                            height="150"
                          />
                        </div>
                        <div class="fbox-content center">
                          <h3 class="color">{t(service.title)}
                          </h3>
                          <p class="text-light">{t(service.description1)}
                          {t(service.description2)}
                          </p>
                        </div>
                        <ul class="iconlist row mt-4 mb-0">
                          {service.features.map((feature) => (
                            <li class="mb-2 col-6 text-light op-08">
                              <i class="icon-check text-smaller text-light op-05"></i>
                              {t(feature)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <CallToAction />
    </>
  );
}
