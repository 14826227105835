import pattern from "../../images/pattern.svg";
import logo from "../../images/logos/d-logo-white.svg";


export default function NoMatch() {
  return (
    <section
      id="content"
      style={{
        backgroundImage: `url(${pattern})`,
      }}
      className="include-header min-vh-60"
    >
      <div className="bg-overlay" style={{ zIndex: "0" }}></div>
      <div
        className="center"
        style={{
          margin: "0",
          position: "absolute",
          top: "50%",
          left: "50%",
          msTransform: "translate(-50%, -50%)",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h1
          className="text-light topmargin-lg"
          style={{
            fontSize: "10rem",
          }}
        >
          404
        </h1>
        <h2>
          <span className="text-light">
            Oops! Page not found :( <br />
          </span>
        </h2>
      </div>
    </section>
  );
}
