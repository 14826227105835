
import bedford from "../images/clients/bedfordny.png";
import mirage from "../images/clients/mirage.png";
import dronek from '../images/clients/Dronek-logo-black.svg';
import immolux from "../images/clients/immolux-logo.jpeg";
import aim from "../images/clients/aim-logo.jpeg";
import Esmerra from "../images/clients/Esmerra.png";
import Kemenani from "../images/clients/Kemenani.png";
import Ours from "../images/clients/Ours.png";
import Capital from "../images/clients/capital.png";
import Dicarlo from "../images/clients/dicarlo.png"

export const clients = [
  bedford,
  dronek,
  mirage,
  immolux,
  aim,
  Esmerra,  
  Kemenani,
  Capital,
  Ours,
  Dicarlo,
];