/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import pattern from "../../images/pattern-randomized.svg";

import {
  MdShareLocation,
  MdSchedule,
  MdOutlineCall,
  MdAlternateEmail,
} from "react-icons/md";
import contactIllu from "../../images/undraw_contact_us_re_4qqt.svg";
import { useTranslation } from "react-i18next";
import "../Home/i18n";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <>
      <section
        id="content"
        style={{
          backgroundImage: `url(${pattern})`,
        }}
        className="include-header min-vh-25 bg-dark m-0"
      >
       
        {/* <div className="bg-overlay" style={{ zIndex: "0" }}></div> */}
        <div className="container my-lg-6 " style={{ zIndex: "1" }}>
          <div className="dark">
            <div className="pt-6 ">
              <div className="row m-0">
                <div class="col-lg-8 m-0 p-0">
                  <div class="dark h-100 d-flex flex-column justify-content-between ">
                    <div className="">
                      <div
                        class="heading-block"
                        style={{ maxWidth: "640px" }}
                        data-animate="fadeInUp"
                      >
                        <h2 class="color mb-4 text-size-xl">{t("Contact_Description")}</h2>
                        <p class="lead fw-normal  mb-5 text-size-md">{t("Contact_Description1")}
                        </p>
                      </div>
                    </div>

                    <div className="m-0">
                      <div
                        class="widget clearfix row m-0"
                        data-animate="fadeInUp"
                      >
                        <div className="mb-2 col-md-6">
                          <h4 class="mb-2 color" style={{ fontSize: "1.3rem" }}>
                            <MdShareLocation />{t("About_Item_Address")}
                          </h4>
                          <p className="ps-4 ">Kalaban Coura, &nbsp; Rue 226</p>
                        </div>
                        <div className="mb-2  col-md-6">
                          <h4 class="mb-2 color" style={{ fontSize: "1.3rem" }}>
                            <MdSchedule />{t("About_Item_Hours")}
                          </h4>
                          <p className="ps-4 ">{t("About_Item_Monday")} - {t("About_Item_Friday")}: 10:00 - 18:00</p>
                        </div>
                        <div className="col-md-6">
                    <h4 class="mb-2 color" style={{ fontSize: "1.3rem" }}>
                      <MdAlternateEmail /> Email
                          </h4>
                      <a
                        href="mailto:info@diplomatechnology.com"
                        class="color"
                        style={{ fontSize: "1rem" }}
                      >
                        <MdAlternateEmail />info@diplomatechnology.com
                      </a>
                    </div>
                        <div className="mb-2 col-md-6">
                          <h4 class="mb-2 color" style={{ fontSize: "1.3rem" }}>
                            <MdOutlineCall />{t("About_Item_Phone")}
                          </h4>
                        <MdOutlineCall /><a href="tel: +1-(646)-705-2738">+1 (646) 705 2738</a><br />
                        <MdOutlineCall /><a href="tel:+223-44-39-70-60">+223 44 39 70 60</a><br />
                        <MdOutlineCall /><a href="tel:+223-76-55-26-55">+223 76 55 26 55</a>
                        </div>
                      </div>
                    </div>
                    <div data-animate="fadeInUp">
                      <a
                        href="https://m.facebook.com/profile.php?id=100071565574119"
                        class="social-icon si-large si-rounded si-borderless si-facebook"
                        target="_blank" rel="noreferrer"
                      >
                        <i class="icon-facebook"></i>
                        <i class="icon-facebook"></i>
                      </a>
                      <a
                        href="https://twitter.com/Diplomatech_"
                        class="social-icon si-large si-rounded si-borderless si-twitter"
                        target="_blank" rel="noreferrer"
                       >
                        <i class="icon-twitter"></i>
                        <i class="icon-twitter"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/diplomatech-technology-5961b6240/"
                        class="social-icon si-large si-rounded si-borderless si-linkedin"
                        target="_blank" rel="noreferrer"
                      >
                        <i class="icon-linkedin"></i>
                        <i class="icon-linkedin"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/diplomatechnology/?hl=fr"
                        class="social-icon si-large si-rounded si-borderless si-instagram"
                        target="_blank" rel="noreferrer"
                      >
                        <i class="icon-instagram"></i>
                        <i class="icon-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 m-auto" data-animate="fadeInRight">
                  <img
                    src={contactIllu}
                    alt="contact"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
