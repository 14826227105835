export const softwaredev = [
  {
    title:"Service_description12",
    description1:
     "Service_description1_web",
    image: "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/000000/external-development-web-design-and-development-flatart-icons-outline-flatarticons-2.png",
    features: [
      "softwaredev_Design",
      "softwaredev_Development",
      "softwaredev_Testing",
      "softwaredev_Hosting",
      "softwaredev_Maintenance",
      "softwaredev_Security",
      "softwaredev_Analytics",
      "softwaredev_Performance",
      "softwaredev_SEO",
    ],
  },
  {
    title:"Service_description13",
    description2:
      "Service_description2_mobile",
    image: "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/000000/external-mobile-app-ux-and-ui-flatart-icons-outline-flatarticons.png",
    features: [
      "softwaredev_UI/UX Design",
      "softwaredev_iOS",
      "softwaredev_Android",
      "softwaredev_Cross Platform",
      "softwaredev_Testing",
      "softwaredev_Maintenance",
      "softwaredev_Security",
      "softwaredev_Analytics",
      "softwaredev_Performance",
      "softwaredev_Landing Page",
    ],
  },
  {
    title:"Service_description14",
    description3:
    "Service_description3_Software",
    image: "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/000000/external-software-web-design-and-development-flatart-icons-outline-flatarticons.png",
    features: [
        "softwaredev_Frontend",
        "softwaredev_Backend",
        "softwaredev_Database",
        "softwaredev_Testing",
        "softwaredev_Maintenance",
        "softwaredev_Security",
        "softwaredev_Performance",
        "softwaredev_Cloud_Services",
    ]
  },

];