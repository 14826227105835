export default function HeadingBlock(props) {
    return (
      <div
        class={"heading-block " + props.className}
        style={{ maxWidth: "640px" }}
      >
        <h5 class={"text-uppercase ls1 text-muted mb-3 " + props.smtitleadclass}>{props.smalltitle}</h5>
        <h2 class={"color mb-4 " + props.titleadclass} style={{fontSize:"3rem"}}>{props.title}</h2>
        <p class={"lead fw-normal  mb-5 " + props.subtitleadclass}>
        {props.subtitle}
        </p>
      </div>
    );
}