/* eslint-disable react/jsx-no-target-blank */
import { projects } from "../../data/projects";
import CallToAction from "../../components/CallToAction";
import PageHead from "../../components/PageHead";
import { useTranslation } from "react-i18next";

export default function Work() {
  const { t } = useTranslation();

  function RenderProject(props) {
    let ordertext = props.orderclass ? "" : "ps-md-4 order-lg-1 order-1";
    let orderimg = props.orderclass ? "" : "order-lg-2 order-2";
    let animate = props.orderclass ? "fadeInRight" : "fadeInLeft";

    return (
      <div className="row m-0 py-3">
        <div className={"col-lg-5 m-auto " + ordertext} data-animate="fadeInUp">
          <div class="title-block">
            <h1 className="color">
              <a href={props.url} target="_blank">
                {props.title}
              </a>
            </h1>
            <span className="text-light op-05">{t(props.service)}</span>
          </div>
          <div className="text-light">
            <p>{t(props.description)}</p>
            <p>{t(props.description1)}</p>
            <p>{t(props.description2)}</p>
            <p>{t(props.description3)}</p>
            <p>{t(props.description4)}</p>
            <p>{t(props.description5)}</p>
            <p>{t(props.description6)}</p>
            <p>{t(props.description7)}</p>
            <p>{t(props.description8)}</p>
            <p>{t(props.description9)}</p>
          </div>{" "}
          <div className="row">
            {props.features.map((feature) => (
              <h5 className="col text-size-small text-light op-05">
                {feature}
              </h5>
            ))}
          </div>
        </div>
        <div className={"col-lg-7 " + orderimg} data-animate={animate}>
          <div
            style={{
              content: "",
              position: "relative",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              boxShadow: "10px 10px 0 0 #117ACA",
            }}
          >
            <a href={props.url} target="_blank">
              <img
                src={props.image}
                alt={props.title}
                className="img-fluid border border-dark border-width-3"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <PageHead smtitle={t("Work_title1")} title={t("Work_title2")} subtitle={t("Work_title3")} />
      <section className="bg-dark mt-">
        <div className="container">
          <div className="">
            {projects.map((project) => (
              <>
                <RenderProject {...project} key={project.id} />
                <div className="divider"></div>
              </>
            ))}
          </div>
        </div>
      </section>
      <CallToAction />
    </>
  );
}
