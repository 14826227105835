export const processList = [

  {
    iconLink:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/000000/external-strategy-leadership-coaching-inipagistudio-mixed-inipagistudio.png",
    title:"title_Strategy",
    description:
    "Service_Process_Strategy",
  },
  {
    iconLink:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/000000/external-design-video-course-platform-inipagistudio-mixed-inipagistudio.png",
    title:"title1_Design",
    description1:
      "Service_Process_Design"
  },
  {
    iconLink:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/000000/external-web-development-web-development-company-inipagistudio-mixed-inipagistudio.png",
    title:"title2_Development",
    description2:
    "Service_Process_Development"
  },
  {
    iconLink:
      "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/000000/external-launch-web-development-company-inipagistudio-mixed-inipagistudio.png",
    title:"title3_Maintenance",
    description3:
      "Service_Process_Maintenance"
  },
];
