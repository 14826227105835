import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import NoMatch from "./pages/NoMatch";
import "./style.scss"
import HeaderNav from "./components/HeaderNav/HeaderNav";
import Footer from "./components/Footer";
import Work from "./pages/work";
import UnderConstruction from "./pages/UnderConstruction";

function App() {
  return (
    <Router forceRefresh={true}>
      <HeaderNav />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Services" component={Services} />
        <Route exact path="/project" component={Work} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/privacy-policy" component={UnderConstruction} />
        <Route path="*" component={NoMatch} />
      </Switch>
      <Footer />
    </Router>
  );
}
export default App;
