export const digitalmarketing = [
  {
    title: "Service_description15",
    description:
      "Service_description_Marketing",
    image:
      "https://img.icons8.com/external-justicon-lineal-justicon/150/4a90e2/external-marketing-marketing-and-growth-justicon-lineal-justicon-1.png",
    features: [
      "service_bullet_Website_Redesign",
      "service_bullet_SEO",
      "service_bullet_Analytics",
      "service_bullet_Social_Media",
      "service_bullet_Content_Marketing",
      "service_bullet_Email_Marketing",
    ],
  },
  {
    title: "Service_description16",
    description2:
      "Service_description2_Promotion",
    image:
      "https://img.icons8.com/external-wanicon-lineal-wanicon/150/4a90e2/external-advertising-marketing-strategy-wanicon-lineal-wanicon.png",
    features: [
      "service_bullet_Advertising",
      "service_bullet_Web_Content",
      "service_bullet_Blog_Articles",
      "service_bullet_Social_Posts",
      "service_bullet_Campaigns",
      "service_bullet_Press_Releases",
    ],
  },
  {
    title: "Service_description17",
    description3:
      "Service_description3_Management",
    image:
      "https://img.icons8.com/external-linector-lineal-linector/150/4a90e2/external-social-media-marketing-seo-marketing-linector-lineal-linector.png",
    features: [
      "service_bullet_Community_Management",
      "service_bullet_Campaign_targeting",
      "service_bullet_Social",
      "service_bullet_Account_Optimization",
    ],
  },
];
