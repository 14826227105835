import softwareDevelopemnt from '../images/software-development.svg';
import digitalizationIllu from '../images/digitalization.svg';
import graphicDesignillu from "../images/brandidentity.png";

export const services = [
  {
    title: "Service_title_1",
    description: "",
    image: softwareDevelopemnt,
    services: ["Service_bullet1", "Service_bullet2", "Service_bullet3"],
  },

  {
    title2: "Service_title_2",
    description: "",
    image: digitalizationIllu,
    services: [
      "Service_bullet4",
      "Service_bullet5",
      "Service_bullet6",
    ],
  },
  {
    title3: "Service_title_3",
    description: "",
    image: graphicDesignillu,
    services: ["Service_bullet7", "Service_bullet8", "Service_bullet9"],
  },
];

