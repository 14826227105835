/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */

import { MdAlternateEmail, MdOutlineCall } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../images/logos/diplomatech-logo-white.svg";
import { digitalization } from "../../data/digitalization";
import { softwaredev } from "../../data/softwaredev";
import { graphicdesign } from "../../data/graphicdesign";
import { useTranslation } from "react-i18next";
import "../../pages/Home/i18n";


export default function Footer() {
const { t } = useTranslation();


  let servicesList = [];
  softwaredev.forEach((service) => {
    servicesList.push(t(service.title)
);
  });

  digitalization.forEach((service) => {
    servicesList.push(t(service.title));
  });
  graphicdesign.forEach((service) => {
    servicesList.push(t(service.title));
  });

  return (
    <footer id="footer" class="bg-dark dark border-color m-0">
      <div class="container text">
        <div class="footer-widgets-wrap">
          <div class="row col-mb-50 m-0">
            <div class="col-lg-5 col-sm-12 mx-auto p-0">
              <div class="widget clearfix ">
                <div class="mb-4">
                  <img
                    src={logo}
                    alt="Diplomatech Logo"
                    style={{ width: "70%" }}
                  />
                </div>
                <div className="mb-3">
                  <p class="mb-4" style={{ fontSize: "1.2rem" }}>
                   {t("footer_text")}
                    <br />
                   {t("footer_text1")}
                  </p>
                  <div className="row mb-3">
                    <div className="col-md-6">
                    <h4 class="mb-2 color" style={{ fontSize: "1.3rem" }}>
                      <MdAlternateEmail /> Email
                          </h4>
                      <a
                        href="mailto:info@diplomatechnology.com"
                        class="color"
                        style={{ fontSize: "1rem" }}
                      >
                        <MdAlternateEmail />info@diplomatechnology.com
                      </a>
                    </div>
                    <div className="col-md-6">
                      <div class="color" style={{ fontSize: "1rem" }}>
                        {" "}
                        <h4 class="mb-2 color" style={{ fontSize: "1.3rem" }}>
                          <MdOutlineCall /> {t("About_Item_Phone")} 
                          </h4>
                        <a
                        class="color"
                        style={{ fontSize: "1rem" }}
                        >
                        <MdOutlineCall /><a href="tel:+1-(646)-705-2738">+1 (646) 705 2738</a><br />
                        <MdOutlineCall /><a href="tel:+223-44-39-70-60">+223  44 39 70 60</a><br />
                        <MdOutlineCall /><a href="tel:+223-76-55-26-55">+223 76 55 26 55</a>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-animate="fadeInUp">
                      <a
                        href="https://m.facebook.com/profile.php?id=100071565574119"
                        class="social-icon si-large si-rounded si-borderless si-facebook"
                        target="_blank" rel="noreferrer"
                      >
                        <i class="icon-facebook"></i>
                        <i class="icon-facebook"></i>
                      </a>
                      <a
                        href="https://twitter.com/Diplomatech_"
                        class="social-icon si-large si-rounded si-borderless si-twitter"
                        target="_blank" rel="noreferrer"
                       >
                        <i class="icon-twitter"></i>
                        <i class="icon-twitter"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/diplomatech-technology-5961b6240/"
                        class="social-icon si-large si-rounded si-borderless si-linkedin"
                        target="_blank" rel="noreferrer"
                      >
                        <i class="icon-linkedin"></i>
                        <i class="icon-linkedin"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/diplomatechnology/?hl=fr"
                        class="social-icon si-large si-rounded si-borderless si-instagram"
                        target="_blank" rel="noreferrer"
                      >
                        <i class="icon-instagram"></i>
                        <i class="icon-instagram"></i>
                      </a>
                    </div>

                {/* <div className="justify-content-between">
                  <a
                    href="asf"
                    class="mx-2 social-icon si-small si-light si-twitter"
                  >
                    <i class="icon-twitter"></i>
                    <i class="icon-twitter"></i>
                  </a>
                  <a
                    href="sadf"
                    class="mx-2 social-icon si-small si-light si-facebook"
                  >
                    <i class="icon-facebook"></i>
                    <i class="icon-facebook"></i>
                  </a>
                  <a
                    href="asf"
                    class="mx-2 social-icon si-small si-light si-instagram"
                  >
                    <i class="icon-instagram"></i>
                    <i class="icon-instagram"></i>
                  </a>

                  <a
                    href="asf"
                    class="mx-2 social-icon si-small si-light si-linkedin"
                  >
                    <i class="icon-linkedin"></i>
                    <i class="icon-linkedin"></i>
                  </a>
                </div> */}
              </div>
            </div>
            <div class="col-lg-2 col-4 mx-auto">
              <div class="widget clearfix">
                <h3 className="color">{t("footer_text4")}</h3>

                <ul class="align-items-stretch" style={{ listStyle: "none" }}>
                  <li className="mb-3">
                    <Link className="h-text-color" exat to="/">
                      {t("HeaderNav_Home")}
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="h-text-color" exat to="/services">
                      {t("HeaderNav_Services")}
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="h-text-color" exat to="/project">
                       {t("HeaderNav_Work")}
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="h-text-color" exat to="/about">
                      {t("HeaderNav_About")}
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link className="h-text-color" exat to="/contact">
                       {t("HeaderNav_Contact")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-5 col-8 m-0">
              <div class="widget clearfix">
                <h3 className="color">{t("Services_footer")}</h3>
                <ul
                  class="align-items-stretch row"
                  style={{ listStyle: "none" }}
                >
                  {servicesList.map((service) => (
                    <li className="mb-3 col-sm-6 ">
                      <Link className="h-text-color" exat to="/services">
                        {service}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="copyrights">
        <div class="container">
          <div class="row col-mb-30 align-items-center">
            <div class="col-md-6 text-center text-md-start color">
             {t("footer_text2")}
              <br />
            </div>
            <div class="col-md-6 text-center text-md-end ">
              <div class="copyright-links ">
                <Link to="/privacy-policy" className="text-light h-text-color">
                 {t("footer_text3")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
