import { Link, NavLink } from "react-router-dom";
import diplomatechLogo from "../../images/diplomatech_white_logo.png";
import "./header.css";
import useScrollingUp from "../../hooks/useScrollingUp";
import { useTranslation } from "react-i18next";
import "../../pages/Home/i18n"

export default function HeaderNav() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const scrollingUp = useScrollingUp();
  let scrollClass = scrollingUp
    ? " sticky-header"
    : " transparent-header";

  let navLinks = {
    Home: { name: t("HeaderNav_Home"), path: "/" },
    services: { name: t("HeaderNav_Services"), path: "/services" },
    work: { name: t("HeaderNav_Work"), path: "/project" },
    about: { name: t("HeaderNav_About"), path: "/about" }, 
    contact: { name: t("Contact"), path: "/contact" },
  };

  function renderNavLinks() {
    return Object.keys(navLinks).map((key) => {
      return (
        <NavLink
          key={key}
          exact to={navLinks[key].path}
          className={(isActive) => "menu-ite" + (!isActive ? "" : " current")}
        >
          <li className="menu-lin">
            <div>{navLinks[key].name}</div>
          </li>
        </NavLink>
      );
    });
  }
  return (
    <header
      id="header"
      class={"header-size-md mt-0 border-bottom-0 " + scrollClass}
    >
      <div id="header-wrap">
        <div class="container">
          <div class="header-row justify-content-lg-center">
            <div id="logo">
              <Link exact to="/" class="standard-logo">
                <img src={diplomatechLogo} alt="diplomatechLogo" />
              </Link>
              <Link exact to="/" class="retina-logo">
                <img src={diplomatechLogo} alt="diplomatechLogo" />
              </Link>
            </div>
            <div id="primary-menu-trigger">
              <svg class="svg-trigger " viewBox="0 0 100 100">
                <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                <path d="m 30,50 h 40"></path>
                <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
              </svg>
            </div>
            <div className="customize-input">   
          <select
            className="custom-select form-control bg-white   border-0"
            onChange={(e) => changeLanguage(e.target.value)}
          >
            <option value="en" icon>{t("Language")}</option>
            <option value="en">{t("English")}
            </option>
            <option value="fr">{t("French")}</option>
          </select>
          
        </div>
            
            <nav class="primary-menu style-e">
              <ul class="menu-container">{renderNavLinks()}</ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
