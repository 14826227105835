export const digitalization = [
  {
    title: "digitalization_description",
    description:
      "We support businesses in their digital decision making to improve their business performance by providing an IT solution and softwares that is tailored to their needs.",
    image: "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/4a90e2/external-coding-digital-economy-business-flatart-icons-outline-flatarticons.png",
    features: [
      "IT Consulting",
      "Cloud Migration",
      "Software Development",
      "ERP, CRM Integration",
    ],
  },
  {
    title: "digitalization_description2",
    description:
      "Customized digital marketing solutions to help businesses grow and thrive in the digital world.",
    image: "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/4a90e2/external-advertising-digital-economy-business-flatart-icons-outline-flatarticons.png",
    features: [
      "Advertising",
      "Marketing",
      "Social Media",
      "Analytics",
      "Comunity Management",
      "SEO",
    ],
  },
  {
    title: "digitalization_description3",
    description:
      "We provide a complte cloud services to help businesses migrate, manage and scale their business to the cloud.",
    image: "https://img.icons8.com/external-flatart-icons-outline-flatarticons/150/4a90e2/external-cloud-storage-digital-economy-business-flatart-icons-outline-flatarticons.png",
    features: [
      "Computing",
      "Security",
      "Storage",
      "Infrastructure",
      "IoT",
      "Big Data",
    ],
  },
];