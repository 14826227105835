import patternbg from "../../images/pattern-randomized.svg";

export default function UnderConstruction() {
  return (
      <>
    <section
      id="content"
      style={{
        backgroundImage: `url(${patternbg})`,
      }}
      className="include-header min-vh-lg-75 min-vh-sm-25 bg-dark"
    >
      <div className="bg-overlay" style={{ zIndex: "0" }}></div>
      <div
        className="container justify-content-center topmargin-lg"
        style={{ zIndex: "1",}}
      >
        <div className="center p-6 dark w-100 h-100">
          <div class="heading-block ">
            <h1 className="text-light text-size-lg" >
                Page Under Construction
            </h1>
            <span class="text-light op-08 text-size">
                We are working hard to bring you the best experience. Stay tuned!
            </span>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
