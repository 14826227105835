/* eslint-disable jsx-a11y/alt-text */
import homeillustration from "../../images/diplomatech-illustration1.svg";
import { services } from "../../data/services";
import agile1 from "../../images/agile.png";
import agile2 from "../../images/agile2.png";
import { technologies } from "../../data/technologies";
import { processList } from "../../data/process";
import { clients } from "../../data/clients";
import "./index.css"
import { Link } from "react-router-dom";
import CallToAction from "../../components/CallToAction";
import { useEffect } from "react";
import anime from "animejs";
import { useTranslation } from "react-i18next";
import "./i18n.js"

export default function Home() {
const { t } = useTranslation();

  useEffect(() => {
    anime({
      targets: ".animateImage",
      keyframes: [{ translateY: 50 }],
      delay: function (el, i) {
        return i * 100;
      },
      direction: "alternate",
      duration: 1500,
      loop: true,
      easing: "easeInSine",
    });
  });
  
  return (
    <>
      <section class="slider-element py-6 block-hero-6 include-header min-vh-md- bg-dark m-0">
        <div class="row align-items-center justify-content-center p-xl-6 m-0">
          <div
            class="col-md-5 m-0 px-xl-6 topmargin align-items-center justify-content-center"
            data-animate="fadeInUp"
          >
            <h1 class="display-4 fw-bold color ">
              {t("home_description_1")}
            </h1>
            <h3 class="text-light">
              {t("home_description_2")}
            </h3>
            <div
              class=""
              style={{
                content: "",
                position: "relative",
                left: "0",
                top: "0",
                width: "25%",
                boxShadow: "4px 4px 0 0 #117ACA",
              }}
            >
              <Link exact to="/about" className="">
                <div className=" center w-100 border border-width-2 p-3 h-bg-dark bg-white color">
                 {t("home_description_3")}
                </div>
              </Link>
            </div>
          </div>
          <div class="col-md-7 m-auto topmargin-lg" data-animate="fadeInUp">
            <div className="animateImage">
              <img
                src={homeillustration}
                alt="home-illustration"
                className="m-auto"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="content" className="bg-dark m-0">
        <div class="content-wrap container dark">
          <div class="container">
            <div
              class="mx-auto center bottommargin heading-block"
              style={{ maxWidth: "640px" }}
            >
              <h5 class="text-uppercase ls1 text-muted mb-3 ">{t("home_service")}</h5>
              <h2 class="color  mb-4 ">{t("home_description_4")}</h2>
              <p class="lead fw-normal  mb-5" style={{ fontSize: "1.5rem" }}>
              {t("home_description_5")}
              </p>
            </div>
          </div>
          <div class="row  bottommargin-lg m-0">
            {services.map((feature) => (
              <div
                class="col-md-4 m-0 p-0 align-items-center"
                data-animate="zoomIn"
              >
                <div class="shadow-lg border center border-dark dark bg-color h-bg-dark m-auto py-4">
                  <Link to="/services">
                      <h3 class="mb-3 text-size-lg px-6 ls3">{t(feature.title)}
                      {t(feature.title2)}
                      {t(feature.title3)}
                      </h3>
                    <p className="text-light center">
                      {feature.services.map((service) => (
                        <span className="">
                          {t(service)}
                          <br />
                        </span>
                      ))}
                    </p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="m-0 bg-azure">
        <div class="p-lg-6">
          <div
            class="mx-auto center mt-4 bottommargin heading-block"
            style={{ maxWidth: "640px" }}
          >
            <h5 class="text-uppercase ls1 text-muted mb-3">{t("home_description_6")}</h5>
            <h2 class="color mb-4">{t("home_description_7")}</h2>
            <p class="lead fw-normal  mb-5 text-dark">
            {t("home_description_8")}
            </p>
          </div>
          <div class="row text-start justify-content-center m-0 py-6">
            {processList.map((item) => (
              <div
                class="col-12 col-md-6 col-xxl-3 p-2 p-md-3 p-xxl- m-0 h-shadow-lg rounded-6"
                data-animate="fadeInUp"
              >
                <div class="fbox-content px-">
                  <img src={item.iconLink} alt="" />
                  <h2 class="text-size-lg my-4 text-dark">{t(item.title)}
                  </h2>
                  <p class="color fw-bold" style={{ fontSize: "1.2rem" }}>
                     {t(item.description)}
                     {t(item.description1)}
                     {t(item.description2)}
                     {t(item.description3)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-dark m-0">
        <div className="row m-0 py-6">
          <div className="col-lg-5">
            <div
              class="mx-auto text-start mt-6 bottommargin heading-block"
              style={{ maxWidth: "640px" }}
            >
              <h5 class="text-uppercase ls1 text-muted mb-3">
                 {t("home_description_9")}
              </h5>
              <h2 class="color mb-4">  {t("home_description_10")}  </h2>
              <p class="lead fw-normal mb-5 dark">  
              {t("home_description_11")}    
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 m-auto justify-content-center"
            data-animate="fadeInRight"
          >
            <div className="m-0 pt-6 row justify-content-center">
              <div className="col-10 col-xl-6 m-auto">
                <img src={agile1} alt="Agile" />
              </div>
              <div className="col-10 col-xl-6 m-auto">
                <img src={agile2} alt="agile1" />
              </div>
            </div>
          </div>
        </div>
        <div className="p-auto p-md-6">
          <div
            class="mx-auto text-center mt-6 bottommargin-lg heading-block"
            style={{ maxWidth: "640px" }}
          >
            <h5 class="text-uppercase ls1 text-muted mb-3">
              {t("home_description_12")}
            </h5>
            <h2 class="color mb-4">{t("home_description_13")}</h2>
            <p class="lead fw-normal  mb-5 dark">
              {t("home_description_14")}
            </p>
          </div>
          <div className="row p-2 p-md-3 justify-content-center m-0">
            {technologies.map((item) => (
              <div
                className="col-12 col-lg-6 col-xxl-3 p-3"
                data-animate="fadeInUp"
              >
                <div
                  class="position-relative"
                  style={{
                    content: "",
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "100%",
                    height: "100%", 
                    boxShadow: "5px 5px 0 0 #117ACA",
                  }}
                >
                  <div
                    className=" bg-azure border border-dark border-width-2 shadow-lg center"
                    style={{ height: "400px" }}
                  >
                    <h3 className="pt-3 ">{t(item.title)}
                    
                    </h3>
                    <div className="row p-1 color m-0">
                      {Object.keys(item.tools).map((key) => (
                        <div className="col-4 color">
                          <div style={{ fontSize: "3rem" }}>
                            {item.tools[key]}
                          </div>
                          <p className="text-dark">{key}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="content" className="bg-white p-lg-6">
        <div class="row justify-content-center align-items-center m-0">
          <div className="col-lg-5">
            <div
              class="mx-auto text-start mt-6 bottommargin heading-block"
              style={{ maxWidth: "640px" }}
            >
              <h5 class="text-uppercase ls1 text-dark op-05 mb-3">
               {t("home_description_15")}
              </h5>
              <h2 class="color mb-4">{t("home_description_16")}</h2>
              <p class="lead fw-normal  mb-5 ">
                {t("home_description_17")}
              </p>
            </div>
          </div>
          <div className="col-lg-7 align-items-end">
            <div className="row justify-content-center m-0">
              {clients.map((item) => (
                <div className="col-6 col-md-4 col-xl-3 p-3 m-auto" data-animate="fadeIn">
                  <img
                    src={item}
                    style={{
                      width: "auto",
                      maxHeight: "150px",
                    }}
                    alt="logo"
                  />
                </div>
              ))}
            </div>
          </div>
          {/* <div class="section bg-transparent">
            <div class="container">
              <div className="row justify-content-center align-items-center">
                {testimonials.map((item) => (
                  <div class="col-lg-6 col-sm-12 p-3">
                    <div
                      style={{
                        content: "",
                        position: "relative",
                        left: "0",
                        top: "0",
                        width: "100%",
                        height: "100%",
                        boxShadow: "-6px 6px 0 #211E1E",
                      }}
                    >
                      <div class="feature-box testimonial fbox-center fbox-bg bg-color border border-width-2 border-light shadow-lg">
                        <div class="fbox-icon bg-transparent" style={{width:"5rem", height:"3.5rem"}}>
                          <img
                            src={item.profile}
                            alt={"Logo " + item.company}
                            class="border border-white border-width-2"
                          />
                        </div>
                        <div class="fbox-content">
                          <h4 class="mb-0 text-dark">{item.name}</h4>
                          <h5 class="text-white-50 fw-normal">
                            {item.company}
                          </h5>
                          <p class="text-larger text-light fst-italic mb-0">
                            {item.quote}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <CallToAction />
    </>
  );
}

